import React from "react";
import "moment-timezone";
import { Affix, Layout, BackTop } from "antd";
import HeaderPage from "./components/HeaderPage";
import PathFinder from "./PathFinder";
import MainPage from "./components/MainPage";
import ServicePage from "./components/ServicePage";
import GuacamolePage from "./components/GuacamolePage";
import ProductPage from "./components/ProductPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const { Footer } = Layout;
class App extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      width: 0,
      menu_selected: "home",
      collapsed: true,
      height: 0,
      contact_form: false,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <BrowserRouter>
        <Layout>
          <BackTop />
          <Affix offsetTop={0}>
            <HeaderPage
              toggleCollapse={() =>
                this.setState({ collapsed: !this.state.collapsed })
              }
              collapsed={this.state.collapsed}
              height={this.state.height}
              width={this.state.width}
            />
          </Affix>
          <Layout>
            <Routes>
              <Route
                path="/services"
                element={
                  <ServicePage
                    height={this.state.height}
                    width={this.state.width}
                  />
                }
              />
              <Route
                path="/products"
                element={
                  <ProductPage
                    height={this.state.height}
                    width={this.state.width}
                  />
                }
              />
              <Route
                path="/guacamole"
                element={
                  <GuacamolePage
                    height={this.state.height}
                    width={this.state.width}
                  />
                }
              />
              <Route
                path="/"
                element={
                  <MainPage
                    height={this.state.height}
                    width={this.state.width}
                  />
                }
              />
            </Routes>
            <PathFinder />
          </Layout>
          <Footer>
            <div align="center">© 2023 Dove I/O, LLC. All Rights Reserved.</div>
          </Footer>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
