import React from "react";
import feature from "../resources/services.jpg";
import "moment-timezone";
import { Typography, Tag, Row, Col, Layout, Card } from "antd";
import {
  BranchesOutlined,
  CheckCircleOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;

const { Meta } = Card;
const { Content } = Layout;

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      blog: {},
      contact_form: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Services | Dove IO</title>
          <meta
            name="description"
            content="Our highly specialized team of software and security engineers are ready to build successful software solutions for your business. We come from diverse technical backgrounds, from deep within the halls of the Department of Defense, to the front lines of commercial industry. Our passion for technology and enthusiasm for building amazing solutions, motivates us to accomplish success."
          />
        </Helmet>
        <Content
          style={{
            paddingLeft: "0%",
            paddingRight: "0%",
          }}
        >
          <Row gutter={[24, 24]}>
            <Col
              span={24}
              xs={24}
              md={24}
              sm={24}
              lg={24}
              style={{
                paddingLeft: this.props.width > 760 ? "20%" : "2%",
                paddingRight: this.props.width > 760 ? "20%" : "2%",
              }}
            >
              <Row gutter={[24, 24]}>
                <Col span={24} xs={24} md={24} sm={24} lg={24}>
                  {this.props.width > 760 ? (
                    <img
                      src={feature}
                      width="100%"
                      alt="Feature"
                      style={{ filter: "brightness(100%)", opacity: 1 }}
                    />
                  ) : (
                    <div />
                  )}
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={24}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <CheckCircleOutlined
                              style={{
                                fontSize: "72px",
                                color: "#00E870",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Build Success With Dove IO</Title>
                            <Text
                              style={{
                                lineHeight: "100%",
                                fontSize: "16px",
                                paddingBottom: "50px",
                              }}
                            >
                              Our highly specialized team of software and
                              security engineers are ready to build successful
                              software solutions for your business. We come from
                              diverse technical backgrounds, from deep within
                              the halls of the Department of Defense, to the
                              front lines of commercial industry. Our passion
                              for technology and enthusiasm for building amazing
                              solutions, motivates us to accomplish success.
                            </Text>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <LikeOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Character Qualities</Title>
                            <Tag style={{ margin: "5px" }} color="magenta">
                              Ethical
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="red">
                              Open
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="volcano">
                              Driven
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="orange">
                              Agile
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="gold">
                              Focused
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="lime">
                              Reliable
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="green">
                              Humble
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="cyan">
                              Measured
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="blue">
                              Smart
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="geekblue">
                              Friendly
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="purple">
                              Geek
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="magenta">
                              Leader
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="red">
                              Knowledgeable
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="volcano">
                              Seasoned
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="orange">
                              Experienced
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="gold">
                              Goal Driven
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="lime">
                              Completionist
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="green">
                              Self-Starter
                            </Tag>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={16}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <BranchesOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Technical Expertise</Title>
                            <Tag style={{ margin: "5px" }} color="magenta">
                              Open Source
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="red">
                              Python
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="volcano">
                              C#
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="orange">
                              C/C++
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="gold">
                              Java
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="lime">
                              GoLang
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="green">
                              Apache Foundation
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="cyan">
                              Guacamole
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="blue">
                              NiFi
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="geekblue">
                              Visual Studio Code
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="purple">
                              Packet Inspection
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="magenta">
                              JavaScript
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="red">
                              ReactJS
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="volcano">
                              Node.JS
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="orange">
                              Machine Learning
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="gold">
                              Big Data
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="lime">
                              MySQL
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="green">
                              MongoDB
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="cyan">
                              ElasticSearch
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="blue">
                              IoT
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="geekblue">
                              Unity3D
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="purple">
                              Natural Language Processing
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="red">
                              Cloud
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="magenta">
                              Amazon Web Services (AWS)
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="volcano">
                              Azure
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="orange">
                              Google Cloud Platform
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="gold">
                              DevOps
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="lime">
                              DataOps
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="green">
                              Hadoop
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="cyan">
                              Linux
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="blue">
                              Exploitation
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="geekblue">
                              Signal Intelligence
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="purple">
                              Data Formats
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="volcano">
                              Data Science
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="magenta">
                              Cyber Security
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="red">
                              HDFS
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="volcano">
                              Spark
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="orange">
                              Kafka
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="gold">
                              Streaming Data
                            </Tag>
                            <Tag style={{ margin: "5px" }} color="lime">
                              More!
                            </Tag>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

export default ServicePage;
