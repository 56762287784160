import { useLocation } from "react-router-dom";

const PathFinder = () => {
  const location = useLocation();
  console.log(location);
  if (
    location.pathname &&
    location.pathname !== "/" &&
    location.pathname !== "/guacamole"
  ) {
    window.location = "/";
  }
  return null;
};

export default PathFinder;
