import React from "react";
import logo from "../resources/dove_io_logo_new.png";
import ContactForm from "./forms/ContactForm";
import "moment-timezone";
import { Modal, message, Layout, Button } from "antd";
import {
  TwitterOutlined,
  LinkedinOutlined,
  MessageOutlined,
} from "@ant-design/icons";

const api =
  "https://pnxqa0ka3b.execute-api.us-east-1.amazonaws.com/production/api";

const { Header } = Layout;
class HeaderPage extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      contact_form: false,
    };

    this.submitContact = this.submitContact.bind(this);
  }

  componentDidMount() {}

  submitContact(values) {
    const hide = message.loading("Sending Information, Please Wait!", 0);
    // Dismiss manually and asynchronously

    let opts = values;
    opts["token"] = "";
    opts["api"] = "contact";
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        setTimeout(hide, 1);
        message.success(
          "Message Sent, Thank you! We will get back to you soon!"
        );
        this.setState({ contact_form: false });
      })
      .catch((e) => {
        setTimeout(hide, 1);
        message.error("Error Submitting Request!");
        console.error("ERROR", e);
      });
  }

  render() {
    return (
      <div>
        {this.props.width > 760 ? (
          <Header
            style={{
              paddingLeft: "1%",
              paddingRight: "1%",
              backgroundColor: "hsl(215, 0%, 2%)",
            }}
          >
            <a href="https://www.dove.io" rel="noopener noreferrer">
              <img
                src={logo}
                alt="logo"
                style={{ height: "50px", verticalAlign: "middle" }}
              />
            </a>

            <div style={{ float: "right" }}>
              <a
                href="https://twitter.com/doveio"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                  }}
                  size="large"
                  shape="circle"
                  icon={<TwitterOutlined />}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/doveio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  type="dashed"
                  style={{
                    marginRight: "10px",
                  }}
                  size="large"
                  shape="circle"
                  icon={<LinkedinOutlined />}
                />
              </a>

              <Button
                onClick={() => this.setState({ contact_form: true })}
                style={{
                  marginRight: "10px",
                }}
                type="primary"
                icon={<MessageOutlined />}
                size="large"
              >
                Contact Us
              </Button>
            </div>
          </Header>
        ) : (
          <Header
            style={{
              paddingLeft: "1%",
              paddingRight: "1%",
              backgroundColor: "hsl(215, 0%, 2%)",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: "35px", verticalAlign: "middle" }}
            />

            <div style={{ float: "right" }}>
              <Button
                onClick={() => this.setState({ contact_form: true })}
                style={{
                  marginRight: "10px",
                }}
                type="primary"
                icon={<MessageOutlined />}
                size="small"
              >
                Contact
              </Button>
            </div>
          </Header>
        )}
        <Modal
          title="Contact Dove IO"
          visible={this.state.contact_form}
          closable={false}
          footer={[
            <Button
              form="contactForm"
              type="primary"
              htmlType="submit"
              key="loginButton"
            >
              Submit
            </Button>,
            <Button
              key="closeButton"
              onClick={() => this.setState({ contact_form: false })}
            >
              Close
            </Button>,
          ]}
          width={760}
        >
          <ContactForm submitContact={this.submitContact} />
          <div align="center">
            <a
              href="https://twitter.com/doveio"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                type="dashed"
                style={{
                  marginRight: "10px",
                }}
                size="large"
                shape="circle"
                icon={<TwitterOutlined />}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/doveio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                type="dashed"
                style={{
                  marginRight: "10px",
                }}
                size="large"
                shape="circle"
                icon={<LinkedinOutlined />}
              />
            </a>
          </div>
        </Modal>
      </div>
    );
  }
}

export default HeaderPage;
