import { Form, Input } from "antd";
import React from "react";
const { TextArea } = Input;

const ContactForm = (props) => {
  function onFinish(values) {
    props.submitContact(values);
  }
  return (
    <Form id="contactForm" labelCol={{ span: 8 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={(values) => onFinish(values)} size="small">
      <Form.Item label="First Name" name="first_name" rules={[{ required: true }]}>
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item label="Last Name" name="last_name" rules={[{ required: true }]}>
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item label="Business Name" name="business_name" rules={[{ required: false }]}>
        <Input placeholder="Business Name" />
      </Form.Item>
      <Form.Item label="E-Mail Address" name="email_address" rules={[{ required: true }]}>
        <Input placeholder="Email Address" />
      </Form.Item>
      <Form.Item label="Description" name="description" rules={[{ required: false }]}>
        <TextArea rows={4} placeholder="Description" />
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
