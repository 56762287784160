import React from "react";
import platform from "../resources/platform.png";
import multimedia from "../resources/multimedia.png";
import models from "../resources/trainmodels.png";
import platform3 from "../resources/PED.png";
import platform4 from "../resources/manufacturing.png";
import "moment-timezone";
import { Image, Typography, Row, Col, Layout, Card } from "antd";
import {
  SearchOutlined,
  ThunderboltOutlined,
  CheckCircleOutlined,
  DollarCircleOutlined,
  PlayCircleOutlined,
  DotChartOutlined,
  AimOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;

const { Meta } = Card;
const { Content } = Layout;
class ProductPage extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      blog: {},
      contact_form: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Products | Dove IO</title>
          <meta
            name="description"
            content="Introducing Dove IO Data Platform, enabling customers to analyze video and images in real-time, utilizing machine learning techniques."
          />
        </Helmet>
        <Content
          style={{
            paddingLeft: "0%",
            paddingRight: "0%",
          }}
        >
          <Col
            span={24}
            xs={24}
            md={24}
            sm={24}
            lg={24}
            style={{
              paddingLeft: this.props.width > 760 ? "20%" : "2%",
              paddingRight: this.props.width > 760 ? "20%" : "2%",
              paddingTop: "50px",
              paddingBottom: "20px",
            }}
          >
            <Row gutter={[12, 12]}>
              <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                <Card hoverable style={{ width: "100%", height: "100%" }}>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <ThunderboltOutlined
                            style={{
                              fontSize: "64px",
                              color: "#ed1c24",
                              margin: "15px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Multimedia Analysis</Title>
                          <Text
                            style={{
                              lineHeight: "100%",
                              fontSize: "16px",
                              paddingBottom: "50px",
                            }}
                          >
                            Powerful real-time identification of objects,
                            people, text, scenes, and activities in images and
                            videos.
                          </Text>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                <Card hoverable style={{ width: "100%", height: "100%" }}>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <SearchOutlined
                            style={{
                              fontSize: "64px",
                              color: "#ed1c24",
                              margin: "15px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Powerful Insights</Title>
                          <Text
                            style={{
                              lineHeight: "100%",
                              fontSize: "16px",
                              paddingBottom: "50px",
                            }}
                          >
                            Advanced searching capabilities with custom
                            user-focused tagging and tag recall for optimal
                            discovery. Explore and analyze data with the most
                            powerful search engine in the world.
                          </Text>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                <Card hoverable style={{ width: "100%", height: "100%" }}>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <ShareAltOutlined
                            style={{
                              fontSize: "64px",
                              color: "#ed1c24",
                              margin: "15px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Data Vision</Title>
                          <Text
                            style={{
                              lineHeight: "100%",
                              fontSize: "16px",
                              paddingBottom: "50px",
                            }}
                          >
                            Scalable real-time data streaming and transformation
                            with seamless experience between design, control,
                            feedback, and monitoring.
                          </Text>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            xs={24}
            md={24}
            sm={24}
            lg={24}
            style={{
              paddingLeft: this.props.width > 760 ? "20%" : "2%",
              paddingRight: this.props.width > 760 ? "20%" : "2%",
            }}
          >
            <Row gutter={[12, 12]}>
              <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                <Card hoverable style={{ width: "100%", height: "100%" }}>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <DotChartOutlined
                            style={{
                              fontSize: "64px",
                              color: "#ed1c24",
                              margin: "15px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Trainable Models</Title>
                          <Text
                            style={{
                              lineHeight: "100%",
                              fontSize: "16px",
                              paddingBottom: "50px",
                            }}
                          >
                            Our system is trainable, allowing customers to
                            educate the system on unique features in multimedia
                            for accurate tagging and comparisons.
                          </Text>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                <Card hoverable style={{ width: "100%", height: "100%" }}>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <DollarCircleOutlined
                            style={{
                              fontSize: "64px",
                              color: "#ed1c24",
                              margin: "15px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Budget Friendly</Title>
                          <Text
                            style={{
                              lineHeight: "100%",
                              fontSize: "16px",
                              paddingBottom: "50px",
                            }}
                          >
                            Pay for the data platform only when you need it and
                            leverage internal business technical infrastructure
                            as needed to keep costs low.
                          </Text>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                <Card hoverable style={{ width: "100%", height: "100%" }}>
                  <Meta
                    description={
                      <div align="center">
                        <div>
                          <PlayCircleOutlined
                            style={{
                              fontSize: "64px",
                              color: "#ed1c24",
                              margin: "15px",
                            }}
                          />
                        </div>
                        <div>
                          <Title level={4}>Trial and Demo Available</Title>
                          <Text
                            style={{
                              lineHeight: "100%",
                              fontSize: "16px",
                              paddingBottom: "50px",
                            }}
                          >
                            Contact us today to learn about setting up a free
                            and no pressure trial of our platform.
                          </Text>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            xs={24}
            md={24}
            sm={24}
            lg={24}
            style={{
              paddingLeft: this.props.width > 760 ? "20%" : "2%",
              paddingRight: this.props.width > 760 ? "20%" : "2%",
            }}
          >
            <Row gutter={[24, 24]}>
              <Col xs={24} md={24} sm={24} lg={24}>
                <div align="center">
                  <CheckCircleOutlined
                    style={{
                      fontSize: "34px",
                      color: "#ed1c24",
                      marginRight: "15px",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "24px",
                      color: "#ffffff",
                      fontWeight: 600,
                    }}
                  >
                    Example Data Platform Use Cases
                  </Text>
                </div>
              </Col>
              <Col xs={24} md={24} sm={24} lg={24}>
                <Card
                  style={{ width: "100%", height: "100%" }}
                  title={
                    <span>
                      <Title level={4}>
                        <AimOutlined
                          style={{
                            fontSize: "20px",
                            color: "#ed1c24",
                            marginRight: "15px",
                          }}
                        />
                        Manufacturing Quality Control
                      </Title>
                    </span>
                  }
                >
                  <Meta
                    description={
                      <div align="center">
                        <Image
                          src={platform4}
                          width="100%"
                          alt="Dove IO Data Platform 2"
                          style={{ opacity: 1 }}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col xs={24} md={24} sm={24} lg={24}>
                <Card
                  style={{ width: "100%", height: "100%" }}
                  title={
                    <span>
                      <Title level={4}>
                        <AimOutlined
                          style={{
                            fontSize: "20px",
                            color: "#ed1c24",
                            marginRight: "15px",
                          }}
                        />
                        Personal Protective Equipment (PPE) Safety Detection
                      </Title>
                    </span>
                  }
                >
                  <Meta
                    description={
                      <div align="center">
                        <Image
                          src={platform3}
                          width="100%"
                          alt="Dove IO Data Platform 2"
                          style={{ opacity: 1 }}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col xs={24} md={24} sm={24} lg={24}>
                <Card
                  style={{ width: "100%", height: "100%" }}
                  title={
                    <span>
                      <Title level={4}>
                        <AimOutlined
                          style={{
                            fontSize: "20px",
                            color: "#ed1c24",
                            marginRight: "15px",
                          }}
                        />
                        Security and Data Monitoring
                      </Title>
                    </span>
                  }
                >
                  <Meta
                    description={
                      <div align="center">
                        <Image
                          src={platform}
                          width="100%"
                          alt="Dove IO Data Platform 2"
                          style={{ opacity: 1 }}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col xs={24} md={24} sm={24} lg={24}>
                <Card
                  style={{ width: "100%", height: "100%" }}
                  title={
                    <span>
                      <Title level={4}>
                        <AimOutlined
                          style={{
                            fontSize: "20px",
                            color: "#ed1c24",
                            marginRight: "15px",
                          }}
                        />
                        Streaming Multimedia Analysis
                      </Title>
                    </span>
                  }
                >
                  <Meta
                    description={
                      <div align="center">
                        <Image
                          src={multimedia}
                          width="100%"
                          alt="Dove IO Data Platform Multimedia"
                          style={{ opacity: 1 }}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col xs={24} md={24} sm={24} lg={24}>
                <Card
                  style={{ width: "100%", height: "100%" }}
                  title={
                    <span>
                      <Title level={4}>
                        <AimOutlined
                          style={{
                            fontSize: "20px",
                            color: "#ed1c24",
                            marginRight: "15px",
                          }}
                        />
                        Train Models
                      </Title>
                    </span>
                  }
                >
                  <Meta
                    description={
                      <div align="center">
                        <Image
                          src={models}
                          width="100%"
                          alt="Train Models"
                          style={{ opacity: 1 }}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Content>
      </div>
    );
  }
}

export default ProductPage;
