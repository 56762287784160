import React from "react";
import logo from "../resources/dove_io_logo_new.png";
import feature2 from "../resources/background-highlight1.jpg";
import feature3 from "../resources/feature-dev2.png";
import "moment-timezone";
import moment from "moment";
import {
  Typography,
  Tag,
  Row,
  Col,
  Divider,
  message,
  Layout,
  Button,
  Card,
} from "antd";
import {
  BranchesOutlined,
  SafetyCertificateOutlined,
  CoffeeOutlined,
  MessageOutlined,
  LikeOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";

const api =
  "https://pnxqa0ka3b.execute-api.us-east-1.amazonaws.com/production/api";
const { Title, Text } = Typography;

const { Meta } = Card;
const { Content } = Layout;
class MainPage extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      blog: {},
      contact_form: false,
    };
  }

  componentDidMount() {
    let opts = {};
    opts["token"] = "";
    opts["api"] = "blog";
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.message.data);
        this.setState({ blog: data.message.data });
      })
      .catch((e) => {
        message.error("Error Submitting Request!");
        console.error("ERROR", e);
      });
    window.scrollTo(0, 0);
  }

  render() {
    let stories = [];
    let timezone_display = moment.tz.zone(moment.tz.guess()).abbr(new Date());
    let timezone = moment.tz.guess();

    if (
      this.state.blog &&
      this.state.blog.doveio &&
      this.state.blog.doveio.channel &&
      this.state.blog.doveio.channel.item &&
      this.state.blog.doveio.channel.item
    ) {
      if (Array.isArray(this.state.blog.doveio.channel.item)) {
        this.state.blog.doveio.channel.item.forEach(function (s) {
          stories.push(s);
        });
      } else {
        stories.push(this.state.blog.doveio.channel.item);
      }
    }

    if (
      this.state.blog &&
      this.state.blog.radove &&
      this.state.blog.radove.channel &&
      this.state.blog.radove.channel.item &&
      this.state.blog.radove.channel.item
    ) {
      if (Array.isArray(this.state.blog.radove.channel.item)) {
        this.state.blog.radove.channel.item.forEach(function (s) {
          stories.push(s);
        });
      } else {
        stories.push(this.state.blog.radove.channel.item);
      }
    }

    return (
      <div>
        <Helmet>
          <title>Dove IO | Custom Software Engineering Consulting</title>
          <meta
            name="description"
            content="Dove IO empowers customers with technology and software engineering consulting that breathes creativity, innovation, and performance."
          />
        </Helmet>
        <Content
          style={{
            paddingTop: this.props.width > 760 ? "0%" : "5%",
            paddingLeft: this.props.width > 760 ? "0%" : "5%",
            paddingRight: this.props.width > 760 ? "0%" : "5%",
          }}
        >
          <Row gutter={[24, 24]}>
            {this.props.width > 960 ? (
              <Col span={24} xs={24} md={24} sm={24} lg={24}>
                <div align="center">
                  <img
                    src={feature2}
                    width="100%"
                    alt="Feature"
                    style={{ filter: "brightness(50%)", opacity: 1 }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div>
                      <img
                        src={logo}
                        alt="logo"
                        style={{ height: "128px", verticalAlign: "center" }}
                      />
                    </div>
                  </span>
                </div>
              </Col>
            ) : (
              <div>
                {this.props.width > 760 ? (
                  <Col span={24} xs={24} md={24} sm={24} lg={24}>
                    <div align="center">
                      <img
                        src={feature2}
                        width="100%"
                        alt="Feature"
                        style={{ filter: "brightness(50%)", opacity: 1 }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <div>
                          <img
                            src={logo}
                            alt="logo"
                            style={{ height: "64px", verticalAlign: "center" }}
                          />
                        </div>
                      </span>
                    </div>
                  </Col>
                ) : (
                  <span />
                )}
              </div>
            )}

            <Col
              span={24}
              xs={24}
              md={24}
              sm={24}
              lg={24}
              style={{
                paddingLeft: this.props.width > 760 ? "20%" : "2%",
                paddingRight: this.props.width > 760 ? "20%" : "2%",
              }}
            >
              <Row gutter={[24, 24]}>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <CoffeeOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Creative Solutions</Title>
                            <Text
                              style={{
                                lineHeight: "100%",
                                fontSize: "16px",
                                paddingBottom: "50px",
                              }}
                            >
                              Empowering our customers with technology and
                              software engineering solutions that breathes
                              creativity, innovation, and performance.
                            </Text>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <BranchesOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Data Engineering</Title>
                            <Text
                              style={{
                                lineHeight: "100%",
                                fontSize: "16px",
                                paddingBottom: "50px",
                              }}
                            >
                              We help customers discover powerful data insights
                              in the most elegant fashion possible, in hopes
                              that they can write a meaningful data story.
                            </Text>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <SafetyCertificateOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Security Consulting</Title>
                            <Text
                              style={{
                                lineHeight: "100%",
                                fontSize: "16px",
                                paddingBottom: "50px",
                              }}
                            >
                              Founded by technology innovators with DoD/NSA
                              engineering experience. We help our customers with
                              researching internal leaks, exploitation, and
                              reducing future cyber security risks.
                            </Text>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <LikeOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Character Qualities</Title>
                            <Tag
                              style={{
                                margin: "5px",
                                opacity: 1.0,
                              }}
                              color="red"
                            >
                              Ethical
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="red"
                            >
                              Open
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.9 }}
                              color="red"
                            >
                              Driven
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.85 }}
                              color="red"
                            >
                              Agile
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.8 }}
                              color="red"
                            >
                              Focused
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.75 }}
                              color="red"
                            >
                              Reliable
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.7 }}
                              color="red"
                            >
                              Humble
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.65 }}
                              color="red"
                            >
                              Measured
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.6 }}
                              color="red"
                            >
                              Smart
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.55 }}
                              color="red"
                            >
                              Friendly
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.5 }}
                              color="red"
                            >
                              Geek
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.45 }}
                              color="red"
                            >
                              Leader
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.4 }}
                              color="red"
                            >
                              Knowledgeable
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.35 }}
                              color="red"
                            >
                              Seasoned
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.3 }}
                              color="red"
                            >
                              Experienced
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.25 }}
                              color="red"
                            >
                              Goal Driven
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.2 }}
                              color="red"
                            >
                              Completionist
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.15 }}
                              color="red"
                            >
                              Self-Starter
                            </Tag>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={16}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <BranchesOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Technical Expertise</Title>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="magenta"
                            >
                              Open Source
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="red"
                            >
                              Python
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="volcano"
                            >
                              C#
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="orange"
                            >
                              C/C++
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="gold"
                            >
                              Java
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="lime"
                            >
                              GoLang
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="green"
                            >
                              Apache Foundation
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="cyan"
                            >
                              Guacamole
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="blue"
                            >
                              NiFi
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="geekblue"
                            >
                              Visual Studio Code
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="purple"
                            >
                              Packet Inspection
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="magenta"
                            >
                              JavaScript
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="red"
                            >
                              ReactJS
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="volcano"
                            >
                              Node.JS
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="orange"
                            >
                              Machine Learning
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="gold"
                            >
                              Big Data
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="lime"
                            >
                              MySQL
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="green"
                            >
                              MongoDB
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="cyan"
                            >
                              ElasticSearch
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="blue"
                            >
                              IoT
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="geekblue"
                            >
                              Unity3D
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="purple"
                            >
                              Natural Language Processing
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="red"
                            >
                              Cloud
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="magenta"
                            >
                              Amazon Web Services (AWS)
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="volcano"
                            >
                              Azure
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="orange"
                            >
                              Google Cloud Platform
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="gold"
                            >
                              DevOps
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="lime"
                            >
                              DataOps
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="green"
                            >
                              Hadoop
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="cyan"
                            >
                              Linux
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="blue"
                            >
                              Exploitation
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="geekblue"
                            >
                              Signal Intelligence
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="purple"
                            >
                              Data Formats
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="volcano"
                            >
                              Data Science
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="magenta"
                            >
                              Cyber Security
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="red"
                            >
                              HDFS
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="volcano"
                            >
                              Spark
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="orange"
                            >
                              Kafka
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="gold"
                            >
                              Streaming Data
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="red"
                            >
                              Private Cellular APN
                            </Tag>
                            <Tag
                              style={{ margin: "5px", opacity: 0.95 }}
                              color="lime"
                            >
                              More!
                            </Tag>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col
              span={24}
              xs={24}
              md={24}
              sm={24}
              lg={24}
              style={{
                paddingLeft: this.props.width > 760 ? "20%" : "2%",
                paddingRight: this.props.width > 760 ? "20%" : "2%",
              }}
            >
              <Row gutter={[24, 24]}>
                {this.props.width > 960 ? (
                  <Col span={24} xs={24} md={24} sm={24} lg={24}>
                    <div align="center">
                      <img
                        src={feature3}
                        width="100%"
                        alt="Feature"
                        style={{ filter: "brightness(50%)", opacity: 1 }}
                      />
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <div>
                          <Text
                            style={{
                              lineHeight: "200%",
                              fontSize: "34px",
                              color: "#ffffff",
                              fontWeight: 900,
                            }}
                          >
                            Press Releases and Blog Posts
                          </Text>
                        </div>
                      </span>
                    </div>
                  </Col>
                ) : (
                  <div>
                    {this.props.width > 760 ? (
                      <Col span={24} xs={24} md={24} sm={24} lg={24}>
                        <div align="center">
                          <img
                            src={feature3}
                            width="100%"
                            alt="Feature"
                            style={{ filter: "brightness(50%)", opacity: 1 }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <div>
                              <Text
                                style={{
                                  lineHeight: "200%",
                                  fontSize: "24px",
                                  color: "#ffffff",
                                  fontWeight: 900,
                                }}
                              >
                                Press Releases and Blog Posts
                              </Text>
                            </div>
                          </span>
                        </div>
                      </Col>
                    ) : (
                      <Col span={24} xs={24} md={24} sm={24} lg={24}>
                        <div align="center">
                          <img
                            src={feature3}
                            width="100%"
                            alt="Feature"
                            style={{ filter: "brightness(50%)", opacity: 1 }}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <div>
                              <Text
                                style={{
                                  lineHeight: "200%",
                                  fontSize: "18px",
                                  color: "#ffffff",
                                  fontWeight: 900,
                                }}
                              >
                                Press Releases and Blog Posts
                              </Text>{" "}
                            </div>
                          </span>
                        </div>
                      </Col>
                    )}
                  </div>
                )}
                {stories.map((source) => {
                  let format = "MM-DD-YYYY h:mm:ss A";
                  let timestamp = new moment(source.pubDate)
                    .tz(timezone)
                    .format(format);

                  let doctags = [];

                  if (source.category && source.category.length > 0) {
                    source.category.forEach(function (c) {
                      doctags.push(
                        <Tag style={{ margin: "10px" }} key={source.link + c}>
                          {c}
                        </Tag>
                      );
                    });
                  }

                  return (
                    <Col
                      key={source.link}
                      span={24}
                      xs={24}
                      md={24}
                      sm={24}
                      lg={24}
                      xl={12}
                    >
                      <Card
                        style={{ width: "100%", height: "100%" }}
                        title={
                          <span>
                            <Text
                              style={{
                                lineHeight: "200%",
                                fontSize: "16px",
                                color: "#ffffff",
                              }}
                            >
                              <MessageOutlined
                                style={{
                                  fontSize: "20px",
                                  color: "#ed1c24",
                                  marginRight: "15px",
                                }}
                              />
                              Posted By {source.creator}
                            </Text>
                          </span>
                        }
                      >
                        <Meta
                          title={
                            <div>
                              <Button
                                onClick={() =>
                                  window.open(source.link, "_blank") ||
                                  window.location.replace(source.link)
                                }
                                style={{
                                  fontSize: "14px",
                                }}
                                type="link"
                                icon={<BulbOutlined />}
                                size="large"
                              >
                                {source.title}
                              </Button>
                            </div>
                          }
                          description={
                            <div align="center">
                              {doctags}
                              <Divider />
                              <Text
                                style={{
                                  lineHeight: "100%",
                                  fontSize: "12px",
                                  color: "#cec0c0",
                                }}
                              >
                                {" "}
                                Posted on {timestamp + " " + timezone_display}
                              </Text>
                            </div>
                          }
                        />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

export default MainPage;
