import React from "react";
import feature from "../resources/services.jpg";
import guac from "../resources/guac.png";
import html5 from "../resources/html5.png";
import "moment-timezone";
import { Typography, Row, Col, Layout, Divider, Card } from "antd";
import { BranchesOutlined, LaptopOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

const { Title, Text } = Typography;

const { Meta } = Card;
const { Content } = Layout;

class GuacamolePage extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      blog: {},
      contact_form: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Apache Guacamole Consulting | Dove IO</title>
          <meta
            name="description"
            content="Dove IO has extensive knowledge of implementation and customization of Apache Guacamole. Our company has technical expertise on staff to ensure that Apache Guacamole is implemented with a security focused architecture. Our team has experience implementing Apache Guacamole to enable select remote administrators to securely access IoT enabled energy field analyzer globally."
          />
        </Helmet>
        <Content
          style={{
            paddingLeft: "0%",
            paddingRight: "0%",
          }}
        >
          <Row gutter={[24, 24]}>
            <Col
              span={24}
              xs={24}
              md={24}
              sm={24}
              lg={24}
              style={{
                paddingLeft: this.props.width > 760 ? "20%" : "2%",
                paddingRight: this.props.width > 760 ? "20%" : "2%",
              }}
            >
              <Row gutter={[24, 24]}>
                <Col span={24} xs={24} md={24} sm={24} lg={24}>
                  {this.props.width > 760 ? (
                    <img
                      src={feature}
                      width="100%"
                      alt="Feature"
                      style={{
                        filter: "brightness(100%)",
                        opacity: 1,
                      }}
                    />
                  ) : (
                    <div />
                  )}
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={24}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <div>
                            <LaptopOutlined
                              style={{
                                fontSize: "72px",
                                color: "#00E870",
                                margin: "15px",
                              }}
                            />
                          </div>
                          <div>
                            <Title level={4}>Apache Guacamole Support</Title>
                            <Text
                              style={{
                                lineHeight: "100%",
                                fontSize: "16px",
                                paddingBottom: "50px",
                              }}
                            >
                              Dove IO has extensive knowledge of implementation
                              and customization of Apache Guacamole. Our company
                              has technical expertise on staff to ensure that
                              Apache Guacamole is implemented with a security
                              focused architecture. Our team has experience
                              implementing Apache Guacamole to enable select
                              remote administrators to securely access IoT
                              enabled energy field analyzer globally.
                            </Text>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={16}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div>
                          <div align="center">
                            <BranchesOutlined
                              style={{
                                fontSize: "64px",
                                color: "#ed1c24",
                                margin: "15px",
                              }}
                            />
                            <Title level={4}>What is Apache Guacamole?</Title>
                          </div>
                          <div>
                            <Text
                              style={{
                                lineHeight: "100%",
                                fontSize: "16px",
                                paddingBottom: "50px",
                              }}
                            >
                              <a
                                rel="noopener noreferrer"
                                href="https://guacamole.apache.org/"
                                target="_blank"
                              >
                                Apache Guacamole
                              </a>{" "}
                              is a clientless remote desktop gateway. It
                              supports standard protocols like VNC, RDP, and
                              SSH. Desktops accessed through Guacamole need not
                              physically exist. With both Guacamole and a
                              desktop operating system hosted in the cloud, you
                              can combine the convenience of Guacamole with the
                              resilience and flexibility of cloud computing.
                            </Text>
                          </div>
                          <Divider />

                          <div>
                            <Text
                              style={{
                                lineHeight: "100%",
                                fontSize: "16px",
                                paddingBottom: "50px",
                              }}
                            >
                              Dove IO specializes in custom implementation of
                              Apache Guacamole in a secure fashion with
                              specialized security techniques.
                              <br />
                              <br />
                              <ul style={{ marginLeft: "10px" }}>
                                <li style={{ padding: "10px" }}>
                                  Custom Developed Integration of Apache
                                  Guacamole in existing or custom web
                                  application
                                </li>
                                <li style={{ padding: "10px" }}>
                                  User Authentication Roles for Remote Access to
                                  Specific Endpoints
                                </li>
                                <li style={{ padding: "10px" }}>
                                  Temporary creation of secure tunnels for
                                  access to endpoints for limited period of
                                  time.
                                </li>

                                <li style={{ padding: "10px" }}>
                                  Private Cloud Network Security Groups
                                  Protecting Endpoint Access
                                </li>
                              </ul>
                            </Text>
                          </div>
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={24} xs={24} md={24} sm={24} lg={24} xl={8}>
                  <Card hoverable style={{ width: "100%", height: "100%" }}>
                    <Meta
                      description={
                        <div align="center">
                          <img
                            src={guac}
                            width="100%"
                            alt="Feature"
                            style={{
                              filter: "brightness(100%)",
                              opacity: 1,
                              margin: "10px",
                            }}
                          />
                          <img
                            src={html5}
                            width="100%"
                            alt="Feature"
                            style={{
                              filter: "brightness(100%)",
                              opacity: 1,
                              margin: "10px",
                            }}
                          />
                        </div>
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

export default GuacamolePage;
